.agentboard-container {
  background-color: #000;
  color: #fff;
  padding: 80px 20px;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agentboard-label {
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  margin-bottom: 24px;
  display: inline-block;
}

.agentboard-title {
  font-size: 48px;
  margin-bottom: 24px;
  font-family: 'SK-Modernist-Bold', sans-serif;
}

.text-gray {
  color: #808080;
}

.agentboard-description {
  color: #808080;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 48px;
}

.agent-board-card {
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02));
  border-radius: 24px;
  padding: 40px;
  width: 100%;
  max-width: 1200px;
  backdrop-filter: blur(10px);
}

.board-title {
  font-size: 32px;
  margin-bottom: 32px;
}

.agents-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-bottom: 32px;
}

.agent-card {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  padding: 24px;
  text-align: left;
}

.agent-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.agent-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.agent-actions {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.action-btn {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s;
}

.action-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.discover-btn {
  background: #6c5ce7;
  border: none;
  color: white;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 auto;
  cursor: pointer;
  transition: transform 0.2s;
}

.discover-btn:hover {
  transform: scale(1.05);
}

@media (max-width: 1024px) {
  .agents-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .agents-grid {
    grid-template-columns: 1fr;
  }
  
  .agentboard-title {
    font-size: 36px;
  }
}

.container-image {
  max-width: 90%;
  height: auto;
  margin-top: 2rem;
} 