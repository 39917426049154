.container {
  background-color: #000;
  padding: 4rem 2rem;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'General Sans Regular', sans-serif;
}

.badge {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  font-family: 'General Sans Regular', sans-serif;
}

.content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.title {
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 4rem;
  font-weight: 600;
  font-family: 'General Sans Medium', sans-serif;
}

.dimmed {
  color: rgba(255, 255, 255, 0.6);
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 4rem;
}

.card {
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  min-height: 400px;
  background-size: cover;
  background-position: center;
}


.cardContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  text-align: left;
}

.cardContent h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1rem;
  color: white;
  text-align: left;
  font-family: 'General Sans Medium', sans-serif;
}

.cardContent p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  text-align: left;
  font-family: 'General Sans Regular', sans-serif;
}

@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }

  .cardContainer {
    grid-template-columns: 1fr;
  }
} 