@font-face {
    font-family: 'General Sans Regular';
    src: url('../public/fonts/GeneralSans-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'General Sans Medium';
    src: url('../public/fonts/GeneralSans-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }